import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '../node_modules/font-awesome/css/font-awesome.min.css'; 

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import Favicon from 'react-favicon'
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import reducer from "./store/reducer";
import './i18n';
const App = lazy(()=> import('./App'));

const store = createStore(reducer);

let url = 'edrazbe.ico'
const hostname = window.location.hostname;
const organization = hostname.split('.');
if(organization[0] !== null && organization[0] !== 'edrazbe') {
  url = organization[0] + '.ico'
}

ReactDOM.hydrate(
  <Suspense fallback={<div>Loading language...</div>}>
      <Favicon url={"/favicon/" + url} />
      <Provider store={store}>
        <CookiesProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </Provider>
  </Suspense>, document.getElementById('root'));

serviceWorker.unregister();
